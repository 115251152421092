import React from "react";
import styled from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Wrapper = styled.div`
  margin: 0 2rem;
  h1 {
    text-align: center;
  }
`;
const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`web design`, `Waterloo`, `website`, `webapp`, `react`]} />
    <Wrapper>
      <h1>Welcome</h1>
      <p>Here's the site. Let's just call it a work in progress.</p>
    </Wrapper>
  </Layout>
);

export default IndexPage;
